import Web3 from 'web3'
import { useContext } from 'react';
import ConnectedContext from '../../context/connectedcontext';
import ModalVisibilityContext from '../../context/modalvisiblitycontext';
import Web3Context from '../../context/web3context';
// import Web3ProviderContext from '../../context/web3ProviderContext';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import { getScratchBalance } from '../utils/scratch-functions';
import { providers } from './providers';
import { webStorage } from '../../config';



function MetamaskButton(){

    // //web 3 provider
    // const {web3Provider, setWeb3Provider} = useContext(Web3ProviderContext)

    //set up for web3 and account context
    const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)

    //set up for connected logic context
    const {connected, setConnected} = useContext(ConnectedContext)

    //set up for Modal Visibility Context
    const {modalVisibility, setModalVisibility} = useContext(ModalVisibilityContext)

    // set up contract read and write providers
    const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)

    
    //called when pressing on the metamask button inside the connect modal
    function Connect(){

        //initialize web3
        const web3temp = new Web3(Web3.givenProvider || "ws://localhost:8545");

        //set and propagate web3 through the entire app
        // setWeb3(web3temp)
    

        //ask for access from metamask
        web3temp.eth.requestAccounts().then(function(accounts) {
            const account = accounts[0]
            // setWeb3Provider(null)


            // set contract read and write
            setContractReadProviders(providers.readProvider || Web3.givenProvider || "ws://localhost:8545")
            // setContractReadProviders("https://boomersquad.xstaking.sg/" || Web3.givenProvider || "ws://localhost:8545")
            setContractWriteProviders(Web3.givenProvider || "ws://localhost:8545")
            
            //set and propagate account
            setAccount(account)
            //web3temp.eth.getBalance(accounts[0], function(err, result) {
            web3temp.eth.getBalance(accounts[0], function(err, result) {
                if (err) {
                    console.log(err)
                } else {
                    console.log(web3temp.utils.fromWei(result, "ether") + " ETH")
                    setBalance(web3temp.utils.fromWei(result, "ether"))
                }
            })
            web3temp.eth.getChainId().then(function(result:any){
                setChainID(result)
                console.log(result)
            })
            getScratchBalance(Web3.givenProvider || "ws://localhost:8545", accounts[0]).then(function(scratchResult){
                setScratchBalance(scratchResult as string)
                console.log('Scratch balance:', scratchResult)

        }).then(function(anything){
            setConnected(true)
            localStorage.setItem('isWalletConnected', 'true')
            setModalVisibility(false)

            })
        });

    }
    return(
        <div className='provider-style' onClick={Connect}>Metamask<img className='provider-image' src={webStorage + '/navbar/metamasklogo.png'} alt="metamasklogo"/></div>
    )
}
export default MetamaskButton