import { useContext } from 'react';
import ConnectedContext from '../../context/connectedcontext';
import ModalVisibilityContext from '../../context/modalvisiblitycontext';
import Web3 from 'web3';
import Web3Context from '../../context/web3context';
// import Web3ProviderContext from '../../context/web3ProviderContext';
import Web3ContractReadWriteContext from '../../context/web3ContractReadWriteContext';
import { getScratchBalance } from '../utils/scratch-functions';
import { DeFiWeb3Connector } from '@deficonnect/web3-connector'
import { providers } from './providers';
import { webStorage } from '../../config';


function WalletExtensionButton(){

    // //web 3 provider
    // const {web3Provider, setWeb3Provider} = useContext(Web3ProviderContext)
 

        //set up for web3 and account context
        const {account, setAccount, balance, setBalance, chainID, setChainID, scratchBalance, setScratchBalance} = useContext(Web3Context)

        //set up for connected logic context
        const {connected, setConnected} = useContext(ConnectedContext)
    
        //set up for Modal Visibility Context
        const {modalVisibility, setModalVisibility} = useContext(ModalVisibilityContext)

        // set up contract read and write providers
        const {contractReadProviders, setContractReadProviders, contractWriteProviders, setContractWriteProviders} = useContext(Web3ContractReadWriteContext)
    
        
        //called when pressing on the metamask button inside the connect modal
        async function Connect(){

            const connector = new DeFiWeb3Connector({
                supportedChainIds: [25],
                appName: 'https://www.boomersquad.io/',
                chainType: 'eth', // only support 'eth' for DeFiWeb3Connector
                chainId: '25', // for eth is 1
                rpcUrls: {
                  25: 'https://evm-cronos.crypto.org/',
                },
              })
          
            // const connector = new DeFiWeb3Connector({
            //     supportedChainIds: [25],
            //     rpc: {
            //       25: "https://evm.cronos.org/", // cronos mainet
            //     },
            //     pollingInterval: 15000,
            //   });

            setTimeout(() => setModalVisibility(!modalVisibility), 1000)
            //brings up QR Modal
            await connector.activate();
            
            const provider = await connector.getProvider();
    
            const web3 = new Web3(provider);

            // set contract read and write
            setContractReadProviders(providers.readProvider || provider || "ws://localhost:8545")
            setContractWriteProviders(provider || "ws://localhost:8545")
            
           
            //set and propagate web3 through the entire app
            // setWeb3(web3temp)
    
            //ask for access from metamask
            web3.eth.getAccounts().then(function(accounts) {
                const account = accounts[0]

            //set and propagate account
                setAccount(accounts[0])
                console.log(accounts[0])
    
                web3.eth.getBalance(accounts[0], function(err, result) {
                    if (err) {
                        console.log(err)
                    } else {
                        console.log(web3.utils.fromWei(result, "ether") + " ETH")
                        setBalance(web3.utils.fromWei(result, "ether"))
                    }
                })
                    web3.eth.getChainId().then(function(result:any){
                        setChainID(result)
                        // setWeb3Provider(provider)
                }).catch(function(error) {
                    console.log(error)
                    setChainID(25)
                })
                getScratchBalance("https://boomersquad.xstaking.sg/", accounts[0]).then(function(scratchResult){
                    setScratchBalance(scratchResult as string)
                    console.log('Scratch balance:', scratchResult)
    
            }).then(function(anything){
                setConnected(true)
                localStorage.setItem('isWalletConnected', 'true')
                
                })
                
            });      
        }
    
    return(
        <div className='provider-style' onClick={Connect}>Crypto.com Defi Wallet<img className='provider-image' src={webStorage + '/navbar/walletextensionlogo.png'} alt="walletextensionlogo" /></div>
    )
}
export default WalletExtensionButton

//<Button onClick={Connect}type="primary" block style={styles.providerStyle}><Row justify="space-between"><Col>{text1}</Col><Col><img src={webStorage + '/templogo.png'} alt="templogo" style={{textAlign: 'right'}} /></Col></Row></Button>

//<Button onClick={Connect}type="primary" block style={styles.providerStyle}><Row justify="space-between">Crypto.com Defi Connect<img src={webStorage + '/templogo.png'} alt="templogo" style={{textAlign: 'right'}} /></Row></Button>
